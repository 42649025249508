import React from "react";
import FachschaftenListe from "./fachschaftenListe";
const Edit = (props) => {
  return (
    <div>
      <FachschaftenListe />
    </div>
  );
};
export default Edit;
