/*
This file is autogenerated. Don't change it directly.
Instead, change the "addons" setting in your package.json file.
*/

const projectConfigLoader = require('@root/config');
import plonevoltoSlate from '@plone/volto-slate';
import voltoAsta from 'volto-asta';
import mbardevoltoFullcalendarBlock from '@mbarde/volto-fullcalendar-block';
import eeacmsvoltoAnchors from '@eeacms/volto-anchors';
import eeacmsvoltoAccordionBlock from '@eeacms/volto-accordion-block';
import eeacmsvoltoBanner from '@eeacms/volto-banner';

const addonsInfo = [{"modulePath":"/app/node_modules/@plone/volto/packages/volto-slate/src","packageJson":"/app/node_modules/@plone/volto/packages/volto-slate/package.json","version":"17.17.0","isPublishedPackage":false,"isRegisteredAddon":false,"name":"@plone/volto-slate","addons":[]},{"modulePath":"/app/src/addons/volto-asta/src","packageJson":"/app/src/addons/volto-asta/package.json","version":"0.1.0","isPublishedPackage":false,"isRegisteredAddon":true,"name":"volto-asta","addons":[]},{"name":"@mbarde/volto-fullcalendar-block","version":"0.7.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/app/node_modules/@mbarde/volto-fullcalendar-block/src","packageJson":"/app/node_modules/@mbarde/volto-fullcalendar-block/package.json","addons":[]},{"name":"@eeacms/volto-anchors","version":"0.6.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/app/node_modules/@eeacms/volto-anchors/src","packageJson":"/app/node_modules/@eeacms/volto-anchors/package.json","addons":[]},{"name":"@eeacms/volto-accordion-block","version":"10.4.6","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/app/node_modules/@eeacms/volto-accordion-block/src","packageJson":"/app/node_modules/@eeacms/volto-accordion-block/package.json","addons":[]},{"name":"@eeacms/volto-banner","version":"4.0.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/app/node_modules/@eeacms/volto-banner/src","packageJson":"/app/node_modules/@eeacms/volto-banner/package.json","addons":[]}];
export { addonsInfo };

const safeWrapper = (func) => (config) => {
  const res = func(config);
  if (typeof res === 'undefined') {
    throw new Error("Configuration function doesn't return config");
  }
  return res;
}

const projectConfig = (config) => {
  return typeof projectConfigLoader.default === "function" ? projectConfigLoader.default(config) : config;
}

const load = (config) => {
  const addonLoaders = [plonevoltoSlate, voltoAsta, mbardevoltoFullcalendarBlock, eeacmsvoltoAnchors, eeacmsvoltoAccordionBlock, eeacmsvoltoBanner];
  if(!addonLoaders.every((el) => typeof el === "function")) {
    throw new TypeError(
      'Each addon has to provide a function applying its configuration to the projects configuration.',
    );
  }
  return projectConfig(addonLoaders.reduce((acc, apply) => safeWrapper(apply)(acc), config));
};
export default load;
